import "./App.css";
import Home from "./Pages/Home";
import ContactPage from "./Pages/ContactPage";
import EventPage from "./Pages/EventPage";
import { Route, Routes } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ar, en, he } from "./languages";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    he: { translation: he },
    ar: { translation: ar },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/events/:eventId" element={<EventPage />} />
      <Route
        path="/events/:eventId/contacts/:contactId"
        element={<ContactPage />}
      />
    </Routes>
  );
}

export default App;

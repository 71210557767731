import axios from "axios";

export const createEvent = async (event) => {
  var formData = new FormData();
  formData.append("eventTitle", event.title);
  formData.append("phoneNumber", event.phone);
  formData.append("greetingMessage", event.greeting);
  formData.append("greetingMessageAr", event.greetingAr);
  formData.append("greetingMessageHe", event.greetingHe);
  formData.append("eventDate", new Date(event.eventDate).toISOString());
  formData.append("dueDate", new Date(event.dueDate).toISOString());
  formData.append("guestsFile", event.guestsFile);
  formData.append("orderId", event.orderId);
  formData.append("location", event.location);
  //   formData.append("ticketFile", event.ticketFile);

  await axios.post(
    "https://mabrookfunctions.azurewebsites.net/api/CreateEvent?code=ia3g3lxihrzKd5c7s_EpaaAOvPaG5KnWunIbCXH6I5-zAzFuGcZ74Q==",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  console.log("Event was craeted successfully!");
};

export const getEvent = async (eventId) => {
  return await axios.get(
    `https://mabrookfunctions.azurewebsites.net/api/events/${eventId}?code=h8rLlszCfoSxzP8R6QSIRFea68Kn6OTkME-2LX6DT69TAzFudYbhnw==`
  );
};

export const getContact = async (eventId, contactId) => {
  return await axios.get(
    `https://mabrookfunctions.azurewebsites.net/api/events/${eventId}/contacts/${contactId}?code=07uI9UD4TPlETwzvUIx8e280EoJx-mRlrS8R8HItRxloAzFu8pMikg==`
  );
};

export const setResponse = async (eventId, contactId, response) => {
  return await axios.post(
    `https://mabrookfunctions.azurewebsites.net/api/events/${eventId}/contacts/${contactId}?code=-mEa3JoQvWAeEzOdUSP7xHb86VqgrFHXev3LKlSbajD_AzFuywtZOw==`,
    response,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const setTableNumber = async (eventId, contactId, tableNumber) => {
  return await axios.post(
    `https://mabrookfunctions.azurewebsites.net/api/events/${eventId}/contacts/${contactId}/tableNumber?code=nycc5daPcFOskRKTdodsBg8NQcbu2d69EMcDD2Fs_McQAzFu492tLw==`,
    { tableNumber: new Number(tableNumber) },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

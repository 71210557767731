import { useEffect, useState } from "react";
import "../App.css";
import { useLocation } from "react-router-dom";
import { getEvent, setTableNumber } from "../api";
import phoneSvg from "../assets/icons/phone-call.svg";
import calendarSvg from "../assets/icons/calendar-two.svg";
import searchSvg from "../assets/icons/search.svg";
import { Popup } from "./Popup";

function EventPage() {
  const location = useLocation();
  const [eventId, setEventId] = useState("");
  const [event, setEvent] = useState({});

  const [eventDate, setEventDate] = useState(null);
  const [items, setItems] = useState([]); // items to show
  const [allItems, setAllItems] = useState([]);
  const [open, setOpen] = useState(false);

  const changeTableNumber = (contactId, tableNumber) => {
    console.log(tableNumber);
    setTableNumber(eventId, contactId, tableNumber)
      .then((response) => {
        alert("Table number was set successfully");
      })
      .catch((error) => {
        alert("Error, please try again");
      });
  };

  const onSearchTextChange = (text) => {
    setItems(
      allItems.filter((item) =>
        item.item.name.toLowerCase().includes(text.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (event.eventDate) {
      const date = new Date(event.eventDate);
      setEventDate(date);
      const allItems = [
        ...event.approved.map((item) => {
          return { item: item, status: "Approved" };
        }),
        ...event.declined.map((item) => {
          return { item: item, status: "Declined" };
        }),
        ...event.notSure.map((item) => {
          return { item: item, status: "Not Sure" };
        }),
      ];
      setItems(allItems);
      setAllItems(allItems);
    }
  }, [event]);

  useEffect(() => {
    setEventId(location.pathname.replace("/events/", ""));
  }, [location]);

  useEffect(() => {
    if (eventId) {
      getEvent(eventId)
        .then((response) => {
          setEvent(response.data);
        })
        .catch((error) => alert("Failed to get event data"));
    }
  }, [eventId]);

  const createItems = () => {
    return items.map((item, i) => {
      return (
        <tr key={i}>
          <td>{item.item.name}</td>
          <td className="status approved">{item.status}</td>
          <td>
            {item.status === "Approved" ? item.item.numberAttendees : "-"}
          </td>
          <td>
            <input
              type="number"
              style={{ width: 50 }}
              min="0"
              defaultValue={item.item.tableNumber ?? "0"}
              onChange={(e) => {
                item.item.tableNumber = e.target.value;
              }}
              onBlur={(e) =>
                changeTableNumber(item.item.id, item.item.tableNumber)
              }
            />
          </td>
        </tr>
      );
    });
  };
  return (
    <div style={{ backgroundColor: "#f6f8fd" }}>
      <nav className="navbar">
        <div className="container-lg">
          <a className="navbar-brand" href="/">
            Mabrook
          </a>
        </div>
      </nav>
      <main>
        <section className="eventDetailsSection">
          <div className="container-lg eventDetailsMain">
            <div className="eventCard d-flex align-items-center justify-content-center flex-wrap">
              {/* <span>
                <img src={require("../assets/imges/event-card.png")} alt="" />
              </span> */}
              <div className="content">
                <h6>{event.title}</h6>
                <div className="subDetails d-flex align-items-center flex-wrap">
                  <a href="#">
                    <img src={phoneSvg} alt="" />
                    {event.phoneNumber}
                  </a>
                  <a href="#">
                    <img src={calendarSvg} alt="" />
                    {eventDate?.toLocaleDateString("en-GB") +
                      " at " +
                      eventDate?.toLocaleTimeString()}
                  </a>
                  <a href="#">
                    <img src={calendarSvg} alt="" />
                    {"Due Date: " +
                      new Date(event.dueDate).toLocaleDateString("en-GB")}
                  </a>
                </div>
              </div>
            </div>
            <div className="row mx-0 guestCards">
              <div className="col-md-4 col-sm-6 px-0 ps-0 pe-sm-2">
                <div className="card approved">
                  <h6>Approved</h6>
                  <h4>{event.approved?.length ?? 0}</h4>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 px-0 ps-sm-2 pe-md-2 pe-sm-0  mt-4 mt-sm-0">
                <div className="card decline">
                  <h6>Decline</h6>
                  <h4>{event.declined?.length ?? 0}</h4>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 px-0 pe-sm-2 ps-md-2 pe-md-0 mt-4 mt-md-0">
                <div className="card notSure">
                  <h6>Not Sure</h6>
                  <h4>{event.notSure?.length ?? 0}</h4>
                </div>
              </div>
            </div>
            <div className="guestListHead">
              <div className="head d-flex align-items-center justify-content-between flex-wrap">
                <h4>Guest List</h4>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={searchSvg} alt="" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Guest"
                    onChange={(e) => onSearchTextChange(e.target.value)}
                  />
                </div>
                <div className="mt-4 mt-sm-0 guestHeadBtn d-flex">
                  <button onClick={() => setOpen(true)}>+ Add Guest </button>
                  {open && (
                    <Popup
                      text="Add new guest"
                      closePopup={() => setOpen(false)}
                    />
                  )}
                </div>
              </div>
              <div className="guestTableMain">
                <div className="tableHead">
                  <table>
                    <thead>
                      <tr>
                        <th>Name of Guest</th>
                        <th>Status</th>
                        <th>Attendees</th>
                        <th>Table Number</th>
                      </tr>
                    </thead>
                    <tbody>{createItems()}</tbody>
                  </table>
                </div>
                <div className="pagination d-flex justify-content-center">
                  <ul className="d-flex justify-content-between align-items-center">
                    {/* <li className="active">1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li> */}
                  </ul>
                </div>
              </div>
              <div className="buttons d-flex justify-content-center align-items-center">
                {/* <button>save</button> */}
                {/* <button className="deteleBtn">Detele</button> */}
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="container-lg">
          <img src={require("../assets/imges/flower.png")} alt="" />
        </div>
      </footer>
    </div>
  );
}

export default EventPage;

import React, { useState } from "react";
import "../Popup.css";
import PhoneInput from "react-phone-number-input";

export const Popup = ({ text, closePopup }) => {
  const [guest, setGuest] = useState({ name: "", phone: "" });

  const addGuest = () => {};

  return (
    <div className="popup-container">
      <div className="popup-body">
        <div style={{ padding: 20 }}>
          <h1>{text}</h1>
          <form>
            <input
              style={{ width: "99%", alignSelf: "start", textAlign: "center" }}
              type="text"
              placeholder="Name"
              value={guest.name}
              onChange={(value) =>
                setGuest({ ...guest, name: value.target.value })
              }
              required
            ></input>
            <PhoneInput
              style={{
                borderRadius: 20,
                margin: 5,
                outline: "none",
              }}
              countries={["IL"]}
              addInternationalOption={false}
              name="phone"
              value={guest.phone}
              onChange={(value) => setGuest({ ...guest, phone: value })}
              required
            />
            <button
              style={{
                margin: 10,
                backgroundColor: "red",
              }}
              onClick={closePopup}
            >
              Cancel
            </button>
            <button type="submit" style={{ margin: 10 }} onClick={addGuest}>
              Add Guest
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

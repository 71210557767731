import { useRef, useState, useEffect } from "react";
import "../App.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { createEvent } from "../api";

import calendar from "../assets/icons/calander.svg";
import uploadSvg from "../assets/icons/upload.svg";
import phoneSvg from "../assets/icons/phone-call.svg";
import globeSvg from "../assets/icons/globe.svg";
import rightupSvg from "../assets/icons/right-up.svg";
import earnSvg from "../assets/icons/earn.svg";
import mabrookVideo from "../assets/videos/mabrook.mp4";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CLIENT_ID } from "../Config/config";

function Home() {
  const { t } = useTranslation();
  const [event, setEvent] = useState({});
  const [fileSelected, setFileSelected] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [orderID, setOrderID] = useState(false);
  const [cost, setCost] = useState(0);
  const [readyToPay, setReadyToPay] = useState(false);

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const fileHandleChange = async (e) => {
    setEvent({ ...event, [e.target.name]: e.target.files[0] });
    if (e.target.files[0]) {
      setFileSelected(true);
      const rowsNum = await getRowsNumber(e.target.files[0]);
      if (!(rowsNum > 0)) {
        setFileSelected(false);
        alert("Invalid csv file");
      } else {
        setCost(rowsNum * 2);
      }
    } else {
      setFileSelected(false);
    }
  };

  const onLanguageChanged = (language) => {
    i18n.changeLanguage(language);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!fileSelected) {
      setShowErrorMessage(true);
      return;
    } else {
      setShowErrorMessage(false);
    }

    if (cost <= 0) {
      alert("Could not caluculate costs, try again!");
      return;
    }

    setReadyToPay(true);
  };

  const fileRef = useRef();
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Event creation",
            amount: {
              currency_code: "USD",
              value: (Math.round((cost / 3.6) * 100) / 100).toFixed(2),
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  useEffect(() => {
    if (success) {
      console.log("Order successful . Your order id is--", orderID);

      createEvent({ ...event, orderId: orderID })
        .then((response) => {
          alert("Event was created Successfully. order number: " + orderID);
        })
        .catch((error) => {
          alert(
            "Error occured. Contact Mabrook support. your oder number is " +
              +orderID
          );
        });
    }
  }, [success]);

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
    });
  };

  const getRowsNumber = async (file) => {
    if (!file || !file.name.endsWith(".csv")) {
      return -1; // Not a CSV file
    }

    const content = await file.text();
    const rows = content.split("\n");
    // Check if the content has at least one row and each row has the same number of columns
    if (rows.length === 0) {
      return 0; // Empty CSV
    }

    const numColumns = rows[0].split(",").length;
    for (let i = 1; i < rows.length; i++) {
      if (rows[i].split(",").length !== numColumns) {
        return -1; // Rows have different number of columns
      }
    }

    return rows.length;
  };

  return (
    <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
      <nav className="navbar">
        <div className="container-lg">
          <a className="navbar-brand" href="/">
            ד"ר מחאג'נה אחמד
          </a>
          <div className="dropdown">
            <select
              name="language"
              onChange={(e) => onLanguageChanged(e.target.value)}
            >
              <option value="en">English</option>
              <option value="ar">العربية</option>
              <option value="he">עברית</option>
            </select>
          </div>
        </div>
      </nav>

      <main>
        <section className="heroSection">
          <div className="container-lg heroMain">
            <div className="row mx-0 text-center text-md-start">
              <div className="col-md-7 px-0 content">
                <h1>
                  {t("title1")}
                  <span>{t("title2")}</span>
                </h1>
                <p>{t("title3")}</p>
                <a href="#create">{t("create-event")}</a>
              </div>
              <div className="col-md-5 px-0 imgHead">
                {clicked ? (
                  <video
                    id="videoPage"
                    autoPlay
                    controls
                    style={{
                      maxWidth: 375,
                      minHeight: 400,
                      borderRadius: 30,
                    }}
                  >
                    <source src={mabrookVideo} type="video/mp4"></source>
                  </video>
                ) : (
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setClicked(true);
                    }}
                    src={require("../assets/imges/hero-img.png")}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="eventSection">
          <div className="container-lg eventMain">
            <div className="head">
              <h3>
                {t("section2-title1")} <br />
                {t("section2-title2")}
              </h3>
            </div>
            <div className="row mx-0">
              <div className="col-md-4 col-sm-6 px-0">
                <div className="card">
                  <span>
                    <img src={globeSvg} alt="" />
                  </span>
                  <h6>{t("section2-title3")}</h6>
                  <p>{t("section2-p1")}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 px-0  mt-5 mt-sm-0">
                <div className="card">
                  <span>
                    <img src={rightupSvg} alt="" />
                  </span>
                  <h6>{t("section2-title4")}</h6>
                  <p>{t("section2-p2")}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 px-0 mt-5 mt-md-0">
                <div className="card">
                  <span>
                    <img src={earnSvg} alt="" />
                  </span>
                  <h6>{t("section2-title5")}</h6>
                  <p>{t("section2-p3")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contactSection" id="create">
          <div className="container-lg contactMain">
            <div className="head">
              <h4>{t("section3-title")}</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row mx-0 align-items-stretch">
                <div className="col-md-5 px-0 pe-md-3 pe-lg-5 order-2 order-md-1">
                  <div
                    className="upload-box"
                    id="uploadBox"
                    onClick={() => {
                      fileRef.current?.click();
                    }}
                  >
                    {event.guestsFile !== undefined && (
                      <div>{event.guestsFile.name}</div>
                    )}
                    <input
                      ref={fileRef}
                      hidden={true}
                      type="file"
                      name="guestsFile"
                      style={{ fontSize: "20px", textAlign: "center" }}
                      onChange={fileHandleChange}
                    />

                    <img src={uploadSvg} alt="" className="upload-icon" />
                    <span className="upload-label">{t("table-file")}</span>
                  </div>
                  <div className="mt-4 btnHead">
                    {!readyToPay && (
                      <input
                        type="submit"
                        className="submitBtn"
                        value={t("create-event")}
                      ></input>
                    )}
                  </div>
                </div>
                <div className="col-md-7 px-0 order-1 order-md-2 formInfo">
                  <div className="inputGroup d-flex align-items-center justify-content-between flex-wrap">
                    <div className="inputHead">
                      <input
                        type="text"
                        name="title"
                        value={event.title}
                        onChange={handleChange}
                        className="form-control"
                        id="eventTitle"
                        placeholder={t("event-title")}
                        required
                      />
                    </div>
                    <div className="input-group inputHead">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img src={phoneSvg} alt="" />
                        </span>
                      </div>
                      <PhoneInput
                        className="form-control"
                        countries={["IL"]}
                        addInternationalOption={false}
                        name="phone"
                        value={event.phone}
                        onChange={(value) =>
                          setEvent({ ...event, phone: value })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="inputGroup">
                    <textarea
                      className="form-control"
                      id="message"
                      name="greeting"
                      placeholder={t("greeting-en")}
                      value={event.greeting}
                      onChange={handleChange}
                      required
                    ></textarea>
                    <textarea
                      className="form-control"
                      id="messageAr"
                      name="greetingAr"
                      placeholder={t("greeting-ar")}
                      value={event.greetingAr}
                      onChange={handleChange}
                      required
                    ></textarea>
                    <textarea
                      className="form-control"
                      id="messageHe"
                      name="greetingHe"
                      placeholder={t("greeting-he")}
                      value={event.greetingHe}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="inputGroup d-flex align-items-center justify-content-between flex-wrap">
                    <div className="input-group inputHead">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={calendar} alt="" />
                      </span>
                      <div>
                        <label>{t("event-date")}</label>
                        <input
                          type="datetime-local"
                          name="eventDate"
                          className="form-control"
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-group inputHead">
                      <span className="input-group-text" id="basic-addon2">
                        <img src={calendar} alt="" />
                      </span>
                      <div>
                        <label>{t("responses-duedate")}</label>
                        <input
                          type="date"
                          name="dueDate"
                          className="form-control"
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="inputGroup">
                    <input
                      className="form-control"
                      placeholder={t("address")}
                      type="text"
                      name="location"
                      onChange={handleChange}
                    ></input>
                  </div>
                  <div className="inputGroup" style={{ marginLeft: 20 }}>
                    <label>{t("cost", { cost })}</label>
                  </div>
                  <div>
                    {showErrorMessage && (
                      <text style={{ color: "red" }}>
                        * Select Guest table file
                      </text>
                    )}
                    {!readyToPay && (
                      <input
                        type="submit"
                        className="submitBtn"
                        value={t("create-event")}
                      ></input>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {cost > 0 && readyToPay && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: 600,
                  }}
                >
                  <PayPalButtons
                    style={{
                      layout: "vertical",
                    }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                  ></PayPalButtons>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      <footer>
        <div className="container-lg">
          <img src={require("../assets/imges/flower.png")} alt="" />
        </div>
      </footer>
    </PayPalScriptProvider>
  );
}

export default Home;

import "../App.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getContact, getEvent, setResponse } from "../api";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { franc } from "franc-min";

function ContactPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const [eventId, setEventId] = useState("");
  const [contactId, setContactId] = useState("");
  const [contact, setContact] = useState({});
  const [event, setEvent] = useState({});
  const [willJoin, setWillJoin] = useState(true);
  const [attendees, setAttendees] = useState(1);
  const [allergies, setAllergies] = useState([]);
  const [eventDate, setEventDate] = useState(null);
  const [selectedLang, setSelectedLang] = useState("ar");

  const setLanguage = (text) => {
    const options = {
      minLength: 3, // Minimum length of text to consider for detection
      whitelist: ["eng", "heb", "arb"], // Include Hebrew in the whitelist
    };

    const lang = franc(text, options);
    if (lang === "und") {
      setSelectedLang("he");
    } else if (lang === "arb") {
      setSelectedLang("ar");
    } else {
      setSelectedLang("en");
    }
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
  }, [selectedLang]);

  useEffect(() => {
    if (contact.name) {
      setLanguage(contact.name);
    } else {
      setSelectedLang("en");
    }
  }, [contact]);

  useEffect(() => {
    if (event.eventDate) {
      const date = new Date(event.eventDate);

      setEventDate(date);
    }
  }, [event]);

  useEffect(() => {
    const [eventId, contactId] = location.pathname
      .split("/")
      .filter((x) => x !== "events" && x !== "contacts" && x !== "");
    setEventId(eventId);
    setContactId(contactId);
  }, [location]);

  useEffect(() => {
    if (eventId && contactId) {
      getContact(eventId, contactId)
        .then((response) => {
          setContact(response.data);
        })
        .catch((error) => alert("Failed to get contact data"));
      getEvent(eventId)
        .then((response) => {
          setEvent(response.data);
        })
        .catch((error) => alert("Failed to get event data"));
    }
  }, [eventId, contactId]);

  const submitResponse = (e) => {
    const response = {
      willJoin,
      numberAttendees: attendees,
      allergies: allergies,
    };

    setResponse(eventId, contactId, response)
      .then((result) => {
        alert("Your response submitted successfully!");
      })
      .catch((error) =>
        alert("error occured while submitting your response, try again!")
      );
  };

  const handleJoinEvent = (join) => {
    setWillJoin(join);
  };

  const handleAttendeesChange = (event) => {
    setAttendees(parseInt(event.target.value, 10));
  };

  const handleAllergyChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setAllergies((prevAllergies) => [...prevAllergies, value]);
    } else {
      setAllergies((prevAllergies) =>
        prevAllergies.filter((allergy) => allergy !== value)
      );
    }
  };

  const onLanguageChanged = (language) => {
    setSelectedLang(language);
  };

  return (
    <>
      <nav className="navbar">
        <div className="container-lg">
          <a className="navbar-brand" href="/">
            Mabrook
          </a>
          <div className="dropdown">
            <select
              name="language"
              value={selectedLang}
              onChange={(e) => onLanguageChanged(e.target.value)}
            >
              <option value="en">English</option>
              <option value="ar">العربية</option>
              <option value="he">עברית</option>
            </select>
          </div>
        </div>
      </nav>

      <main>
        <section className="invitationSection">
          <div className="container-lg invitationMain">
            <div className="card">
              <div className="cardContent">
                <p>{t("hi", { name: contact.name })}</p>
                <p>{t("invited-title")}</p>
                <h2>
                  {selectedLang === "ar"
                    ? event.titleAr
                    : selectedLang === "he"
                    ? event.titleHe
                    : event.title}
                </h2>
                <h5>{eventDate?.toLocaleDateString("en-GB")}</h5>
                <h4>{eventDate?.toLocaleTimeString()}</h4>
                <h6>{event.location}</h6>
              </div>
            </div>
            <div className="joiningHead d-flex justify-content-center justify-content-md-between  align-items-center flex-wrap">
              <h3>{t("join-question")}</h3>
              <div className="buttons d-flex justify-content-center justify-content-md-end">
                <button
                  className="event-form__button"
                  style={{
                    backgroundColor: willJoin === true ? "#7296a4" : "#cddee5",
                  }}
                  onClick={() => handleJoinEvent(true)}
                >
                  {t("yes")}
                </button>
                <button
                  className="event-form__button no"
                  style={{
                    backgroundColor: willJoin === false ? "#7296a4" : "#cddee5",
                  }}
                  onClick={() => handleJoinEvent(false)}
                >
                  {t("no")}
                </button>
                <button
                  style={{
                    backgroundColor: willJoin === null ? "#7296a4" : "#cddee5",
                  }}
                  onClick={() => handleJoinEvent(null)}
                >
                  {t("not-sure")}
                </button>
              </div>
              {willJoin && (
                <div style={{ padding: 20 }}>
                  <label>
                    {t("number-attendees")}
                    <input
                      type="number"
                      style={{ width: 40 }}
                      min="1"
                      value={attendees}
                      onChange={handleAttendeesChange}
                    />
                  </label>
                  <br />
                  <label style={{ marginTop: 5 }}>
                    {t("allrgies-question")}
                  </label>
                  <div style={{ margin: 10 }}>
                    <label style={{ margin: 5 }}>
                      <input
                        type="checkbox"
                        value="Peanuts"
                        checked={allergies.includes("Peanuts")}
                        onChange={handleAllergyChange}
                      />
                      {t("peanuts")}
                    </label>
                    <label style={{ margin: 5 }}>
                      <input
                        type="checkbox"
                        value="Gluten"
                        checked={allergies.includes("Gluten")}
                        onChange={handleAllergyChange}
                      />
                      {t("gluten")}
                    </label>
                    <label style={{ margin: 5 }}>
                      <input
                        type="checkbox"
                        value="Seafood"
                        checked={allergies.includes("Seafood")}
                        onChange={handleAllergyChange}
                      />
                      {t("seafood")}
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="joiningHead d-flex justify-content-center justify-content-md-between  align-items-center flex-wrap">
              <button onClick={submitResponse}>{t("submit")}</button>
              <p>
                {t("response-text")}
                {willJoin
                  ? t("yes")
                  : willJoin === null
                  ? t("not-sure")
                  : t("no")}
              </p>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="container-lg">
          <img src={require("../assets/imges/flower.png")} alt="" />
        </div>
      </footer>
    </>
  );
}

export default ContactPage;
